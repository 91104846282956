export const sidebarList = [
  {
    title: "My Pets",
    path: "/",
    icon: "mdi-paw",
  },
  {
    title: "Connection",
    path: "/connection",
    icon: "mdi-share-variant",
  },
  {
    title: "Profile",
    path: "/profile",
    icon: "mdi-account",
  },
];
