<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    app
    :mini-variant="false"
    :clipped="false"
    color="dark-blue"
    class="app-side-bar"
  >
    <div class="logo">
      <img src="@/assets/svg/logo.svg" alt="mpawer-logo" />
    </div>
    <v-list dark>
      <v-list-item
        v-for="route in routes"
        :key="route.title"
        router
        :to="route.path"
      >
        <v-list-item-action>
          <v-icon>{{ route.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="route.title" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="LOGOUT">
        <v-list-item-action>
          <v-icon>mdi-logout </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Logout

            <v-progress-circular
              v-if="loading.logout"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <img src="@/assets/images/four-paws.png" class="paws" alt="paws" />
  </v-navigation-drawer>
</template>

<script>
import { sidebarList } from "./sidebar-list";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("user");
export default {
  name: "side-bar",
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    ...mapState(["loading"]),
    routes() {
      return sidebarList;
    },
  },
  methods: {
    ...mapActions(["LOGOUT"]),
  },
};
</script>

<style scoped>
.paws {
  position: absolute;
  transform: translate(-50%);
  bottom: 0;
  left: 50%;
  max-width: 100%;
  height: auto;
}

.logo {
  padding: 3rem 1rem 1rem 1rem;
  text-align: center;
}
</style>
