<template>
  <v-main class="light-bg">
    <v-app-bar color="transparent" elevation="0"></v-app-bar>
    <SideBar />
    <v-container>
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
import SideBar from "./components/side-bar.vue";
export default {
  name: "default-layout",
  components: { SideBar }
};
</script>

<style lang="scss" scoped></style>
